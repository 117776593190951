import React from "react";
import Home from "../pages/HomePage/Home";

export default function HomeContainer() {
  return (
    <div className="containers">
      <Home />
    </div>
  );
}
